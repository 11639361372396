import IAuthenticationSettings from "../types/IAuthenticationSettings";
import ISecurityInfo from "../types/ISecurityInfo";
import LoginState from "../types/LoginState";
import xray from 'query-string'
import _ from "lodash";
import { isChrome } from 'react-device-detect'


const OauthUtility = {

    CodesTried: [""],

    //#region handleCode
    async handleCode(
        status: ISecurityInfo,
        settings: IAuthenticationSettings,
        updateState: (newState: LoginState) => void,
        stopRedirect: boolean
    ): Promise<boolean> {
        const [code, scope] = this._getCodeAndScopeFromURL();

        console.log("Code", code);

        if (code != null && code.length > 0) {
            if (_.indexOf(OauthUtility.CodesTried, code) >= 0) {
                OauthUtility._hideQueryString();
                console.log("Repeat code")
                return false;
            }
            OauthUtility.CodesTried.push(code);

            updateState(LoginState.loggingIn);
            try {
                let tokenReturn = await OauthUtility._translateCode(code, scope, settings);
                if (tokenReturn) {
                    if (!stopRedirect) {
                        OauthUtility._hideQueryString();
                    }
                    //if (isChrome) {
                    updateState(LoginState.loggingIn);
                    setTimeout(() => {
                        console.log(`Delayed state change to help with chrome issues..`);
                        updateState(LoginState.loggedIn);

                        let replaceUri = window.location.protocol + "//" + window.location.host;
                        window.location.href = replaceUri;
                    }, 400);
                    //} else {
                    //    updateState(LoginState.loggedIn);
                    //}
                    return true;
                }
                else {
                    updateState(LoginState.accessDenied);
                    if (!stopRedirect) {
                        OauthUtility._hideQueryString();
                    }
                    return true;
                }
            }
            catch (e) {
                console.error(e);
                updateState(LoginState.accessDenied);
                if (!stopRedirect) {
                    OauthUtility._hideQueryString();
                }
                return true;
            }
        }
        console.log("Code not handled");
        return false;
    },
    //#endregion


    _hideQueryString() {
        try {
            let replaceUri = window.location.protocol + "//" + window.location.host;
            let title = window.document.title;

            window.history.replaceState({}, title, replaceUri);
        }
        catch (e) {

        }
    },

    //#region LogInUri
    LogInUri(settings: IAuthenticationSettings): string | null {
        let path = window.location.pathname.trim();
        if (path && ((path.trim().length === 0) || (path === "/undefined"))) {
            path = "/";
        }
        if (!settings.clientId) {
            return null;
        }
        let loginUrl = settings.authUri;
        loginUrl += "?response_type=code";
        loginUrl += "&client_id=" + settings.clientId;
        loginUrl += "&redirect_uri=" + encodeURI(settings.redirectUri);
        loginUrl += "&scope=openid+profile";
        loginUrl += "&state=" + encodeURI(path); //ZbWW_" + (new Date()).getMilliseconds();
        loginUrl += "&nonce=Ry_" + (new Date()).getTime();

        return loginUrl;
    },
    //#endregion

    //#region Check Already Logged in
    IsAlreadyLoggedIn: async (settings: IAuthenticationSettings, updateState: (newState: LoginState) => void): Promise<boolean> => {
        console.log(`isChrome == ${isChrome}`);
        try {
            let response = await fetch("/security");
            console.log("Checking current security", response.status, response.statusText)
            if (response.ok) {

                updateState(LoginState.loggedIn);
                return true;
            }
            else {
                console.error(response);
            }
        }
        catch (e) {
            console.error(e)
        }

        return false;
    },
    //#endregion

    //#region _getCodeAndScopeFromURL
    _getCodeAndScopeFromURL(): [string | undefined, string | undefined] {
        let search = window.location.search;
        console.log(`QueryString Stage 1: ${search}`)
        search = search.replace(/\?\?/, "?");
        search = search.replace(/\?\?/, "?");
        console.log(`QueryString Stage 2: ${search}`)
        let pageParms = _.merge(xray.parse(search), xray.parse(window.location.hash));

        console.log("QS", pageParms);

        if (pageParms && pageParms["code"]) {
            let code = pageParms["code"];
            let scope = pageParms["state"] ?? "/";
            return [String(code), String(scope)];
        }

        return [undefined, undefined];
    },
    //#endregion
    //#region _translateCode
    async _translateCode(code: string, scope: string | undefined, settings: IAuthenticationSettings): Promise<boolean> {
        try {
            if (code && code.length > 0) {
                let uri = settings.translateTokenUri + code;
                let result = await fetch(uri, {
                    method: "POST"
                });
                if (result.ok) {
                    if (await OauthUtility._isSuccessfulReponse(result)) {
                        console.log("Code was translated " + result.status);
                        return true;
                    }
                }
            }
        }
        catch (e) {
        }
        return false;
    },
    //#endregion
    //#region _isSuccessfulReponse
    async _isSuccessfulReponse(r: Response): Promise<boolean> {
        if (r && r.ok) {
            let body = await r.text();

            return body === "LoggedIn";
        }
        return false;
    }
    //#endregion
}

export default OauthUtility;