import { spMainFolderDefinition } from "./usings/useQuickLinks";
import Row from "@churchofjesuschrist/eden-row";
import { Text1 } from "@churchofjesuschrist/eden-text";
import { Folder, Video } from '@churchofjesuschrist/eden-icons'
import LinkFolderType from "./types/LinkFolderType";
import { NavLink } from "react-router-dom";

interface IQuickLinkHeaderProps {
    item: spMainFolderDefinition
}

const _iconSize = "1.8rem"

const QuickLinkHeader = ({ item }: IQuickLinkHeaderProps) => {
    return <div className="sp-main-link">
        <NavLink to={"/list/" + item.label} className="quick-link-header">
            <Row verticalAlign="center">
                {item.folderType === LinkFolderType.movie ? <Video size={_iconSize} /> : <Folder size={_iconSize} />}
                <Text1>{item.label}</Text1>
            </Row>
        </NavLink>
    </div>
}

export default QuickLinkHeader;