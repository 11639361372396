import Row from "@churchofjesuschrist/eden-row";
import MainImage from "./MainImage";
import MainLinks from "./MainLinks";
import useQuickLinks from "./usings/useQuickLinks";
import { ProgressSection } from "@churchofjesuschrist/eden-progress";


const MainContent = () => {
    const [quickLinks, loadingLinks] = useQuickLinks();

    if (loadingLinks) {
        return <div className="aarl-main-links-loading"> <ProgressSection> Loading...</ProgressSection></div>
    }

    return <div className="aarl-page-content"><Row>
        <MainImage></MainImage>
        <MainLinks quickLinks={quickLinks}></MainLinks>
    </Row>
    </div>
}

export default MainContent;

