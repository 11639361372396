const TimeHelperService = {
    getMSFromSeconds: (seconds: number) => {
        return seconds * 1000;
    },
    getMSFromMinutes: (minutes: number) => {
        return TimeHelperService.getMSFromSeconds(60 * minutes);
    },
    getMSFromHours: (hours: number) => {
        return TimeHelperService.getMSFromMinutes(hours * 60);
    }
}

export default TimeHelperService;