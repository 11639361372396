import MovieLink from "./MovieLink";
import useImageLinksPage from "./usings/useImageLinksPage";
import { spMainFolderDefinition } from "./usings/useQuickLinks";
import { ProgressSection } from "@churchofjesuschrist/eden-progress";

interface IMoviePageProp {
    item: spMainFolderDefinition
}

const MoviePage = ({ item }: IMoviePageProp) => {
    const movieLinks = useImageLinksPage(item.contentLink);

    return <>
        {
            movieLinks.length === 0
                ? <div className="aarl-main-links-loading"><ProgressSection>Loading..</ProgressSection></div>
                : movieLinks.map((L, I) => <div key={I}><MovieLink link={L} /></div>)
        }
    </>
}

export default MoviePage