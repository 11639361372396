import IAuthenticationSettings from "../types/IAuthenticationSettings";
import IOauthService from "../types/IOauthService";
import ISecurityInfo from "../types/ISecurityInfo";
import LoginState from "../types/LoginState";
import OauthUtility from "./OauthUtility";

interface ILogOutResults {
    redirect: string
}

const OauthService: IOauthService = {
    logOut: async (): Promise<boolean> => {

        let results = await fetch("/security", {
            method: "DELETE"
        });

        let r: ILogOutResults = await results.json();

        window.location.href = r.redirect;

        return true;
    },
    logIn: async (status: ISecurityInfo, settings: IAuthenticationSettings, updateState: (newState: LoginState) => void): Promise<boolean> => {
        switch (status.state) {
            // log in process is alrady occuring
            case LoginState.loggingIn:
            case LoginState.loggedIn:
                return true;
        }

        updateState(LoginState.loggingIn);

        let uri = OauthUtility.LogInUri(settings);
        console.log(uri)

        if (uri) {
            window.location.href = uri;
            return true;
        } else {
            alert("There are no settings");
            console.log(JSON.stringify(process.env));
        }
        return false;
    },
    checkInitialLogInStatus: async (status: ISecurityInfo, settings: IAuthenticationSettings,
        updateState: (newState: LoginState) => void, stopRedirect?: boolean): Promise<boolean> => {
        if (settings.enabled) {
            updateState(LoginState.initializing);
            if (await OauthUtility.IsAlreadyLoggedIn(settings, updateState)) {
                console.log("Already logged in!");
                return true;
            }
            if (await OauthUtility.handleCode(status, settings,
                updateState, stopRedirect ?? false)) {
                console.log("Code Handled!");
                return true;
            }
            if (settings.autoLogin) {
                console.log("Doing auto login!");
                OauthService.logIn(status, settings, updateState);
            }
            else {
                console.log("Ready to login!");
                updateState(LoginState.ready);
                return false;
            }
        }
        else {
            console.warn("OAuth Settings disabled");
        }
        return true;
    }
}

export default OauthService;