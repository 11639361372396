import ISPListItemDetails from "../../types/SPListItemDetails";
import { IFilters } from "./IFilter";

const translateLanguageCode = () => {
    let userLang = navigator.language;

    switch (userLang.toLowerCase()) {
        case "zh-hans": return "Chinese - Simplified"
        case "zh-hant": return "Chinese - Traditional"
        case "ceb": return "Cebuano"
    }

    userLang = userLang.substring(0, 2);

    switch (userLang.toLowerCase()) {
        case "en": return "English";
        case "hy": return "Armenian"
        case "hm": return "Cambodian"
        case "da": return "Danish";
        case "nl": return "Dutch";
        case "fj": return "Fijian"
        case "fi": return "Finnish"
        case "fr": return "French"
        case "de": return "German"
        case "id": return "Indonesian"
        case "in": return "Indonesian"
        case "it": return "Italian"
        case "ja": return "Japanese"
        case "ko": return "Korean"
        case "mn": return "Mongolian"
        case "nb": return "Nowegian"
        case "pt": return "Portuguese"
        case "ro": return "Romainian"
        case "ru": return "Russian"
        case "sm": return "Samoan"
        case "es": return "Spanish"
        case "sv": return "Swedish"
        case "tl": return "Tagalog"
        case "th": return "Thai"
        case "to": return "Tongan"
        case "uk": return "Ukrainian"
        case "vi": return "Vietnamese"
    }
    return "All";
}


const findUniqueStringList = <T,>(items: T[], getValue: (item: T) => string | null): string[] => {
    if (items && items.length > 0) {
        let unique: string[] = []
        items.forEach(element => {
            let itemValue = getValue(element);
            if (itemValue) {
                unique.push(itemValue);
            }
        });

        const onlyUnique = (value: string, index: number, collection: string[]) => {
            return collection.indexOf(value) === index;
        }

        unique = unique.filter(onlyUnique);

        unique = unique.sort();

        return unique;
    }
    return []
}


//#region applyFilters
const applyFilters = (folderItems: ISPListItemDetails[], filter: IFilters): ISPListItemDetails[] => {
    if (folderItems && folderItems.length > 0) {
        let filtered: ISPListItemDetails[] = [];
        let enlgishAppend: ISPListItemDetails[] = [];
        folderItems.forEach((element) => {
            let passesFilter = true;
            let englishDefer = false;
            if (passesFilter && filter.approvedFilter && filter.approvedFilter.length > 0) {
                if (filter.approvedFilter === "Yes" && !element.fields.correlationApproved) {
                    //console.log(`Rejected item: NOT approved ${element.fields.correlationApproved}`)
                    passesFilter = false;
                }
                else if (filter.approvedFilter === "No" && element.fields.correlationApproved) {
                    //console.log(`Rejected item: approved ${element.fields.correlationApproved}`)
                    passesFilter = false;
                }
            }

            if (passesFilter && filter.qualityFilter && filter.qualityFilter.length > 0) {
                if (element.fields.fileQuality && element.fields.fileQuality.length > 0) {
                    if (element.fields.fileQuality !== filter.qualityFilter) {
                        //console.log(`Rejected item: quality ${element.fields.fileQuality}`)
                        passesFilter = false;
                    }
                }
            }

            if (passesFilter && filter.locationFilter && filter.locationFilter.length > 0) {
                if (element.fields.worldLocation && element.fields.worldLocation.length > 0) {
                    if (element.fields.worldLocation !== filter.locationFilter) {
                        //console.log(`Rejected item: location ${element.fields.worldLocation}`)
                        passesFilter = false;
                    }
                }
            }

            if (passesFilter && filter.nameFilter && filter.nameFilter.length > 0) {
                if (element.fields.linkFilename && element.fields.linkFilename.length > 0) {
                    if (element.fields.linkFilename.toLocaleLowerCase().indexOf(filter.nameFilter.toLocaleLowerCase()) === -1) {
                        //console.log(`Rejected item: name not passfilter: ${element.fields.linkFilename}`)
                        passesFilter = false;
                    }
                }
                else {
                    //console.log(`Rejected item: name is empty ${element.fields.linkFilename}`)
                    passesFilter = false;
                }
            }

            if (passesFilter && filter.languageFilter && filter.languageFilter.length > 0) {
                if (filter.languageFilter !== "All") {
                    let isEnglishFilter = filter.languageFilter === "English";
                    let isEnglishItem = !element.fields.language || element.fields.language === "English"

                    if (element.fields.language !== filter.languageFilter) {
                        if (isEnglishItem && !isEnglishFilter) {
                            englishDefer = true;
                        }
                        else if (element.fields.language !== null) {
                            //console.log(`Rejected item: language ${element.fields.language}`)
                            passesFilter = false;
                        }
                        else {
                            englishDefer = true;
                        }
                    }
                }
            }
            if (passesFilter) {
                if (!englishDefer)
                    filtered.push(element);
                else
                    enlgishAppend.push(element);
            }
        });
        if (enlgishAppend.length > 0) {
            enlgishAppend.forEach(E => filtered.push(E));
        }
        return filtered;
    }

    return folderItems;
}
//#endregion

export { translateLanguageCode, findUniqueStringList, applyFilters }