enum LoginState {
    initializing = 1,
    loggingIn = 2,
    loggedIn = 3,
    expired = 4,
    accessDenied = 5,
    loggedOut = 6,
    notEnabled = 7,
    ready = 100
}

export const TranslateLoginState = (state: LoginState | null) => {
    switch (state as number) {
        case LoginState.accessDenied:
            return "accessDenied " + JSON.stringify(state);
        case LoginState.expired:
            return "expired " + JSON.stringify(state);
        case LoginState.initializing:
            return "initializing " + JSON.stringify(state);
        case LoginState.loggedIn as number:
            return "loggedIn " + JSON.stringify(state);
        case LoginState.loggedOut:
            return "loggedOut" + JSON.stringify(state);
        case LoginState.loggingIn:
            return "loggingIn " + JSON.stringify(state);
        case LoginState.notEnabled:
            return "notEnabled " + JSON.stringify(state);
        case LoginState.ready:
            return "ready " + JSON.stringify(state);
        default:
            return "Other " + JSON.stringify(state);
    }
}

export default LoginState;