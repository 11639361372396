import { createContext, useContext } from "react";
import LoginState from "./types/LoginState";
import ISecurityInfo from "./types/ISecurityInfo";

const SecurityContext = createContext<ISecurityInfo>({ state: LoginState.initializing });

export const useSecurityContext = (): [LoginState] => {
    let statusInfo = useContext(SecurityContext);
    return [statusInfo?.state ?? LoginState.initializing];
}

export { SecurityContext }