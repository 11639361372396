import { useEffect, useState } from "react";
import IAuthenticateProps from "./types/IAuthenticateProps";
import ISecurityInfo from "./types/ISecurityInfo";
import LoginState, { TranslateLoginState } from "./types/LoginState";
import { SecurityContext } from "./useSecurityContext";
import TimeHelperService from "./services/TimeHelperService";
import OauthService from "./services/OauthService";

export const Authenticate = ({ authSettings, children }: IAuthenticateProps): JSX.Element => {
    const [timeHandle, setTimeHandle] = useState<NodeJS.Timeout | null>(null);


    const [info, setInfo] = useState<ISecurityInfo>({
        state: LoginState.initializing
    });
    const setStatus = (newStatus: LoginState): void => {
        console.log(TranslateLoginState(newStatus))
        setInfo({
            state: newStatus
        });
    }

    useEffect(() => {
        switch (info.state) {
            case LoginState.loggedIn:
                if (timeHandle === null) {
                    let timeHandleLocal = setInterval(() => {
                        OauthService.checkInitialLogInStatus(info, authSettings, setStatus, true);
                    }, TimeHelperService.getMSFromMinutes(450));
                    setTimeHandle(timeHandleLocal)
                }
                break;
            default:
                if (timeHandle !== null) {
                    //timeHandle.
                    clearInterval(timeHandle);
                    setTimeHandle(null);
                }
                break;
        }
        return () => {
            if (timeHandle !== null) {
                //timeHandle.
                clearInterval(timeHandle);
                setTimeHandle(null);
            }
        }



        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [info]);

    useEffect(() => {
        OauthService.checkInitialLogInStatus(info, authSettings, setStatus);
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []);



    return <SecurityContext.Provider value={info}>
        {children}
    </SecurityContext.Provider>;
}