import { useState } from "react";

const ReadCurrentValue = (sessionKey: string) => {
    if (sessionKey && sessionKey.length > 0) {
        let val = window.sessionStorage.getItem(sessionKey);
        if (val) {
            let parsed = JSON.parse(val);
            return parsed;
        }
    }
    return null;
}

const useSessionStorageValue = <T,>(sessionKey: string): [value: T | null, setValue: (val: T | null) => void] => {
    const [value, setValue] = useState<T | null>(() => {
        return ReadCurrentValue(sessionKey);
    });

    const userSetValue = (val: T | null) => {
        if (val) {
            window.sessionStorage.setItem(sessionKey, JSON.stringify(val));
        }
        else {
            window.sessionStorage.removeItem(sessionKey);
        }
        setValue(val);
        console.log(`Changing value for ${sessionKey} to ${val}`);
    }

    return [value, userSetValue];
}

export default useSessionStorageValue;