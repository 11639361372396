import { ChangeEvent } from "react";
import { Select } from "@churchofjesuschrist/eden-form-parts";
import { FilterRow, TD } from "@churchofjesuschrist/eden-table";
import { Input } from "@churchofjesuschrist/eden-form-parts";
import { IFilterData } from "./languages/useFilters";

interface ILanguageFilterRowProps {
    filterInfo: IFilterData
}

const LanguageFilterRow = ({ filterInfo }: ILanguageFilterRowProps) => {

    //#region  Filter Changes
    const onChangeNameFilter = (e: ChangeEvent<HTMLInputElement>) => {
        let value = e.target.value;
        filterInfo.setFilter({ change: "name", filter: value });
    }
    const onChangeLanguageFilter = (e: ChangeEvent<HTMLOptionElement>) => {
        let value = e.target.value;
        filterInfo.setFilter({ change: "language", filter: value });
    }
    const onChangeLocationFilter = (e: ChangeEvent<HTMLOptionElement>) => {
        let value = e.target.value;
        filterInfo.setFilter({ change: "location", filter: value });
    }
    const onChangeQualityFilter = (e: ChangeEvent<HTMLOptionElement>) => {
        let value = e.target.value;
        filterInfo.setFilter({ change: "quality", filter: value });
    }
    const onChangeCorrelationFilter = (e: ChangeEvent<HTMLOptionElement>) => {
        let value = e.target.value;
        filterInfo.setFilter({ change: "correlation", filter: value });
    }
    //#endregion

    return <FilterRow>
        <TD type="controls"></TD>
        <TD type="controls"></TD>
        <TD type="controls">
            <Input name="nameFilter" onChange={onChangeNameFilter} />
        </TD>
        <TD type="controls">
            <Select name="languageFilter" value={filterInfo.filter.languageFilter} onChange={onChangeLanguageFilter}>
                <option value="All">All</option>
                {
                    filterInfo.langauges.map((L) => <option key={L} value={L}>{L}</option>)
                }
            </Select>
        </TD>
        <TD type="controls">
            <Select name="locationFilter" value={filterInfo.filter.locationFilter} onChange={onChangeLocationFilter}>
                <option value="">All</option>
                {
                    filterInfo.locations.map((L) => <option key={L} value={L}>{L}</option>)
                }
            </Select>
        </TD>
        <TD type="controls">
            <Select name="qualityFilter" value={filterInfo.filter.qualityFilter} onChange={onChangeQualityFilter}>
                <option value="">All</option>
                {
                    filterInfo.qualities.map((L) => <option key={L} value={L}>{L}</option>)
                }
            </Select>
        </TD>
        <TD type="controls">
            <Select name="correlationFilter" value={filterInfo.filter.approvedFilter} onChange={onChangeCorrelationFilter}>
                <option value="">Any</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
            </Select>
        </TD>
    </FilterRow>
}



//export default LanguagePicker;

export { LanguageFilterRow }