import QuickLinkHeader from "./QuickLinkHeader";
import { spMainFolderDefinition } from "./usings/useQuickLinks";
import Row from "@churchofjesuschrist/eden-row";

interface IMainLinksArgs {
    quickLinks: spMainFolderDefinition[]
}

const MainLinks = ({ quickLinks }: IMainLinksArgs) => {

    const getItems = (isLeft: boolean): spMainFolderDefinition[] => {
        let items: spMainFolderDefinition[] = [];
        for (let i = isLeft ? 0 : 1; i < quickLinks.length; i += 2) {
            let item = quickLinks[i];
            items.push(item);
        }
        return items;
    }

    return <><Row columnGapSize={"48"}>
        <div>
            {
                getItems(true).map((M, I) => <QuickLinkHeader item={M} key={I} />)
            }
        </div>
        <div>
            {
                getItems(false).map((M, I) => <QuickLinkHeader item={M} key={I} />)
            }
        </div>
    </Row>
    </>
}

export default MainLinks;