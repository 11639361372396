import { MediaModal } from "@churchofjesuschrist/eden-media-modal";
import { Video } from "@churchofjesuschrist/eden-video-player";
import ISPListItemDetails from "../types/SPListItemDetails";
import { useEffect, useState } from "react";

interface IMovieModalProps {
    item: ISPListItemDetails | null
    folderId: string
    active: boolean
    setActive: (value: boolean) => void
}

const MovieModal = ({ item, folderId, active, setActive }: IMovieModalProps) => {
    const [uri, setUri] = useState<string | null>(item ? "/namedfilecontent/" + encodeURI(item.fields.linkFilename) + "?itemId=" + item.id + "&folderId=" + folderId : null)

    useEffect(() => {
        setUri(item ? "/namedfilecontent/" + encodeURI(item.fields.linkFilename) + "?itemId=" + item.id + "&folderId=" + folderId : null);
    }, [item, folderId])
    const closeModal = () => {
        setActive(false);
    };
    return (
        <>
            {active && (
                <MediaModal onClose={closeModal}>
                    <div className="sp-movie">
                        <Video src={uri} />
                    </div>
                </MediaModal>
            )}
        </>
    );
}

export default MovieModal;