import Home from "./controls/Home";
import ItemDetails from "./controls/ItemDetails";
import Login from "./controls/Login";

const AppRoutes = [
  {
    path: '/list/:linkName',
    element: <ItemDetails></ItemDetails>
  },
  {
    path: '/login',
    element: <Login></Login>
  },
  {
    path: '/logon',
    element: <Login></Login>
  },
  {
    index: true,
    element: <Home />
  },
];

export default AppRoutes;
