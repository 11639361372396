import { useEffect, useState } from "react";
import ISPListItemDetails from "../../types/SPListItemDetails"
import { IFilters } from "./IFilter"
import { FilterChange } from "./IFilterChangeParms"
import { applyFilters, findUniqueStringList, translateLanguageCode } from "./LanguageHelpers";
import _ from "lodash";

export interface IFilterData {
    filteredItems: ISPListItemDetails[]
    setFilter: (change: FilterChange) => void
    langauges: string[]
    locations: string[]
    qualities: string[]
    filter: IFilters
}

const useFilters = (items: ISPListItemDetails[]): IFilterData => {
    const [filters, setFilters] = useState<IFilters>(initialFilters);
    const [filtered, setFiltered] = useState<ISPListItemDetails[]>([]);
    const [locations, setLocations] = useState<string[]>([]);
    const [langauges, setLanguages] = useState<string[]>([]);
    const [quality, setQuality] = useState<string[]>([]);

    const setFilter = (change: FilterChange) => {
        let copy = { ...filters };
        switch (change.change) {
            case "correlation":
                copy.approvedFilter = change.filter;
                break;
            case "language":
                copy.languageFilter = change.filter;
                break;
            case "location":
                copy.locationFilter = change.filter;
                break
            case 'name':
                copy.nameFilter = change.filter;
                break;
            case "quality":
                copy.qualityFilter = change.filter;
                break;
        }

        setFilters(copy);
        setFiltered(applyFilters(items, copy));
    }

    const isArrayEqual = (x: any, y: any) => {
        return _(x).differenceWith(y, _.isEqual).isEmpty();
    };

    //#region intial load
    useEffect(() => {
        if (!isArrayEqual(applyFilters(items, filters), filtered)) {
            console.log("Filters changed");
            setFiltered(applyFilters(items, filters));
        }
        else {
            console.log("No Filter change");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items])
    //#endregion

    useEffect(() => {
        //console.log(`Setting Filters`);

        if (!isArrayEqual(findUniqueStringList(items, (i) => i.fields.language || null), langauges)) {
            console.log("Language List changed");
            setLanguages(findUniqueStringList(items, (i) => i.fields.language || null))
        }
        if (!isArrayEqual(findUniqueStringList(items, (i) => i.fields.worldLocation || null), locations)) {
            console.log("Locations List changed");
            setLocations(findUniqueStringList(items, (i) => i.fields.worldLocation || null))
        }
        if (!isArrayEqual(findUniqueStringList(items, (i) => i.fields.fileQuality || null), quality)) {
            console.log("Quality List changed");
            setQuality(findUniqueStringList(items, (i) => i.fields.fileQuality || null))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items]);

    return {
        filteredItems: filtered,
        langauges: langauges,
        locations: locations,
        qualities: quality,
        setFilter: setFilter,
        filter: filters
    }
}

const initialFilters = (): IFilters => {
    return {
        nameFilter: "",
        languageFilter: translateLanguageCode(),
        locationFilter: "",
        qualityFilter: "",
        approvedFilter: ""
    }
}

export default useFilters;