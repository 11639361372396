
interface IMovieLinkProps {
    link: string
}

const MovieLink = ({ link }: IMovieLinkProps) => {
    return <>
        <p className="sp-brightCoveMovie">
            <center><iframe width={600} height={450} title={link} src={link}>
            </iframe></center>
        </p>
    </>
}

export default MovieLink;