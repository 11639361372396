import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import App from './App';
//import * as serviceWorkerRegistration from './serviceWorkerRegistration';
//import reportWebVitals from './reportWebVitals';
import IAuthenticationSettings from './security/types/IAuthenticationSettings';
import { Authenticate } from './security/Authenticate';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import Fonts from "@churchofjesuschrist/eden-fonts";

//import Normalize from "@churchofjesuschrist/eden-normalize";

let AuthSettings: IAuthenticationSettings = {
    enabled: true,
    authUri: process.env.REACT_APP_AUTH_URI as string,// "https://id.churchofjesuschrist.org/oauth2/default/v1/authorize",
    autoLogin: true,
    clientId: process.env.REACT_APP_CLIENT_ID as string, //"0oagqxaappCP5g8fW357",
    logoutUri: "",
    redirectUri: process.env.REACT_APP_REDIRECT_URI as string, //"https://localhost:5001/logon",
    refreshTokenUri: "",
    tokenLifeInMinutes: 480,
    translateTokenUri: "/security?id="
}


const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
//const rootElement = document.getElementById('root');


const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

//const root = createRoot(rootElement);

root.render(
    <Authenticate authSettings={AuthSettings}>
        <BrowserRouter basename={baseUrl ?? undefined}>
            <App />
            </BrowserRouter></Authenticate>);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(console.log);
