import ISPListItemDetails from "../types/SPListItemDetails";
import { TR, TD } from "@churchofjesuschrist/eden-table";
import { VideoPlayer, Preview } from '@churchofjesuschrist/eden-icons'
import ItemIcon from "./ItemIcon";
import CorrelationApproved from "./CorrelationApproved";
import { Link } from "react-router-dom";
import { Download as DownloadButton } from "@churchofjesuschrist/eden-action-bar";

interface IFolderItemProps {
    item: ISPListItemDetails
    folderId: string
    showMedia: (item: ISPListItemDetails) => void
}

const FolderItem = ({ item, folderId, showMedia }: IFolderItemProps) => {
    //const [isDownloading, setIsDownloading] = useState(false);

    const onViewMedia = () => {
        showMedia(item);
    }

    const files: any[] = [
        {
            title: "Download",
            items: [{
                download: "/namedfilecontent/" + encodeURI(item.fields.linkFilename) + "?itemId=" + item.id + "&folderId=" + folderId,
                text: item.fields.linkFilename,
                href: "/namedfilecontent/" + encodeURI(item.fields.linkFilename) + "?itemId=" + item.id + "&folderId=" + folderId
            }]
        }
    ]

    return <>
        <TR>
            <TD>
                <div><DownloadButton className="aarl-download-eden" items={files}></DownloadButton></div>
            </TD>
            <TD>
                {
                    item.fields.docIcon === "mp4"
                        ? <div onClick={onViewMedia} className="aarl-download" title="View"><VideoPlayer size="1.7rem" /></div>
                        : <Link target="_blank" to={"/namedfilecontent/" + encodeURI(item.fields.linkFilename) + "?preview=true&itemId=" + item.id + "&folderId=" + folderId} className="aarl-download" title="Preview"><Preview size="1.7rem" /></Link>
                }
            </TD>
            <TD><ItemIcon docType={item.fields.docIcon ?? ""} />&nbsp; <span className="aarl-document-label">{item.fields.linkFilename}</span></TD>
            <TD>{item.fields.language}</TD>
            <TD>{item.fields.worldLocation}</TD>
            <TD>{item.fields.fileQuality}</TD>
            <TD><CorrelationApproved approved={item.fields.correlationApproved} /></TD>
        </TR ></>
}

export default FolderItem;