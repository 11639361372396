import { MoreFormalH1 } from "@churchofjesuschrist/eden-headings";
import MainContent from "./MainContent";

const Home = () => {
    return <>
        <div className="aarl-spacer1"></div>
        <center><MoreFormalH1>Area Auditor Resource Library</MoreFormalH1></center>
        <div className="aarl-spacer2"></div>
        <MainContent></MainContent>
    </>
}

export default Home