import { useEffect, useState } from "react"
import ISPListItemDetails from "../../types/SPListItemDetails";
import useSessionStorageValue from "./useSessionStorageValue";

const useFolderContents = (contentLink: string): [contents: ISPListItemDetails[], loading: boolean] => {
    const CACHEKEY = "FOLDER_CONTENTS_" + contentLink;
    const [expand, setExpand] = useSessionStorageValue<ISPListItemDetails[]>(CACHEKEY);
    const [loading, setLoading] = useState(true)

    const loadfolderContent = async () => {
        if (expand && expand.length >= 0) {
            if (loading) {
                setLoading(false);
            }
            return;
        }
        if (contentLink && contentLink.length > 0) {
            let results = await fetch("/foldercontent?folderId=" + contentLink);
            if (results.ok) {
                let json = await results.json();
                setExpand(json);
                setLoading(false);
            }
        }
    }

    useEffect(() => {
        console.debug("Loading folder content...");
        loadfolderContent();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contentLink]);

    return [expand ?? [], loading]
}

export default useFolderContents