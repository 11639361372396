import { useEffect, useState } from "react";
import ISPListItemDetails from "../types/SPListItemDetails";
import FolderItem from "./FolderItem";
import useFolderContents from "./usings/useFolderContents";
import { spMainFolderDefinition } from "./usings/useQuickLinks";
import { Table, THead, TR, TH, TBody } from "@churchofjesuschrist/eden-table";
import { ProgressSection } from "@churchofjesuschrist/eden-progress";
import MovieModal from "./MovieModal";
import { LanguageFilterRow } from "./LanguagePicker";
import useFilters from "./languages/useFilters";

interface IFolderContentsProp {
    item: spMainFolderDefinition
}

const FolderContents = ({ item }: IFolderContentsProp) => {
    const [folderItems, loadingFolder] = useFolderContents(item.contentLink);
    const [playingItem, setPlayingItem] = useState<ISPListItemDetails | null>(null);
    const [activePlayer, setActivePlayer] = useState(false);

    useEffect(() => {
        console.log("Loading folder is ", loadingFolder)
        console.log("Folder items:", folderItems)
    }, [loadingFolder, folderItems]);

    const filters = useFilters(folderItems);

    const toggleActivePlayer = (state: boolean) => {
        setActivePlayer(state);
        if (!state) {
            setPlayingItem(null);
        }
    }

    const showMedia = (item: ISPListItemDetails) => {
        setPlayingItem(item);
        setActivePlayer(true);
    }

    return <>
        {
            loadingFolder
                ? <div className="aarl-main-links-loading"><ProgressSection>Loading..</ProgressSection></div>
                : <>
                    <MovieModal active={activePlayer} setActive={toggleActivePlayer} folderId={item.contentLink} item={playingItem} />

                    <Table>
                        <THead>
                            <TR>
                                <TH scope="col"></TH>
                                <TH scope="col"></TH>
                                <TH scope="col">Name</TH>
                                <TH scope="col">Language</TH>
                                <TH scope="col">World Location</TH>
                                <TH scope="col">File Quality</TH>
                                <TH scope="col">Correlation Approved</TH>
                            </TR>
                        </THead>
                        <TBody>
                            <LanguageFilterRow filterInfo={filters}></LanguageFilterRow>
                            {filters.filteredItems && filters.filteredItems.length > 0
                                ? filters.filteredItems.map((M, i) => <FolderItem showMedia={showMedia} key={i} item={M} folderId={item.contentLink} ></FolderItem>)
                                : <>
                                    <TR>
                                        <td className="aarl-emptyfolder-message " colSpan={7}><div>No Items</div></td>
                                    </TR>
                                </>
                            }
                        </TBody>
                    </Table></>
        }
    </>
}

export default FolderContents;