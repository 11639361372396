import { WorkforceHeader } from "@churchofjesuschrist/eden-workforce-header";
import PageWrapper from "@churchofjesuschrist/eden-page-wrapper";


interface LayoutProps {
  children: JSX.Element
}

const Layout = ({ children }: LayoutProps) => {

  return <>
    <WorkforceHeader name={<a href="/">Area Audit Resource Library</a>} />
    <PageWrapper>
      <div className='content-main'>
        {children}
      </div>
    </PageWrapper>
  </>
}

export default Layout;