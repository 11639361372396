import { CircleSelectedFilled, AlertFilled } from '@churchofjesuschrist/eden-icons'

interface ICorrelationApprovedProps {
    approved: boolean
}

const CorrelationApproved = ({ approved }: ICorrelationApprovedProps) => {
    return <>
        {
            approved
                ? <span className='correlation-approved-check'><CircleSelectedFilled size="1.7rem" /></span>
                : <span className='correlation-notapproved-check'><AlertFilled size="1.7rem" /></span>
        }
    </>
}

export default CorrelationApproved