
import { FontIcon } from '@fluentui/react/lib/Icon';
import { Pdf } from '@churchofjesuschrist/eden-icons'

interface IItemIconProps {
    docType: string
}

const ItemIcon = ({ docType }: IItemIconProps) => {
    switch (docType) {
        case "mp4": return <Pdf className='aarl-fluent-doc-icon aarl-moviedoc ' />;
        case "pdf": return <FontIcon className='aarl-fluent-doc-icon aarl-pdfdoc' iconName='PDF' />;
        case "pptx": return <FontIcon className='aarl-fluent-doc-icon aarl-powerpointdoc' iconName='PowerPointDocument' />;
        case "ppsx": return <FontIcon className='aarl-fluent-doc-icon aarl-powerpointdoc' iconName='PowerPointDocument' />;
        case "xlsx": return <FontIcon className='aarl-fluent-doc-icon aarl-exceldoc' iconName='ExcelDocument' />
        case "docx": return <FontIcon className='aarl-fluent-doc-icon aarl-wordoc' iconName='WordDocument' />
        default: return <FontIcon className='aarl-fluent-doc-icon aarl-unknowndoc' iconName='Document' />
    }
}

export default ItemIcon;