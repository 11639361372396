import { Text1 } from "@churchofjesuschrist/eden-text";
import OauthService from "../security/services/OauthService";

const LogOut = () => {
    const onLogOut = () => {
        OauthService.logOut();
    }

    return <div className="aarl-fullwidth">
        <div className="aarl-logoutblock">
            <span className="aarl-pointer" onClick={onLogOut}>
                <Text1>Log Out</Text1>
            </span>
        </div>
    </div>
}

export default LogOut;