import { useEffect, useState } from "react";
import LinkFolderType from "../types/LinkFolderType";
import useSessionStorageValue from "./useSessionStorageValue";



export interface spMainFolderDefinition {
    folderType: LinkFolderType
    label: string
    contentLink: string
}

const mainDefaultFolder: spMainFolderDefinition[] = [
    { folderType: LinkFolderType.folder, label: "Area Auditor", contentLink: "53c66b17-ae7e-475f-9206-c077bca4cc7f" },
    { folderType: LinkFolderType.movie, label: "Area Auditor Training", contentLink: "Area-Auditor-Training.aspx" },
    { folderType: LinkFolderType.movie, label: "Assistant Area Auditor Orientation", contentLink: "Assistant-Area-Auditor-Orientation.aspx" },
    { folderType: LinkFolderType.movie, label: "Assistant Area Auditor Training", contentLink: "Assistant-Area-Auditor-Training.aspx" },
    { folderType: LinkFolderType.folder, label: "FM Group Auditor", contentLink: "fdcebd5e-a7f6-4c21-9817-15d6040be419" },
    { folderType: LinkFolderType.folder, label: "Investigating Local Unit Defalcations", contentLink: "c4fee18a-6c07-403c-ae91-40606a2d785a" },
    { folderType: LinkFolderType.folder, label: "Local Unit Training", contentLink: "5129b568-15a3-4232-b07b-caf38dd1c26b" },
    { folderType: LinkFolderType.folder, label: "LUFAS and CDOL", contentLink: "22b77e1a-572c-40bb-b684-52de6836ba33" },
    { folderType: LinkFolderType.folder, label: "Templates and Forms", contentLink: "c20debf8-32b9-4ff1-a1d5-b61fecf04bdf" },
    { folderType: LinkFolderType.folder, label: "Other Resources", contentLink: "5b435381-8cee-4c51-ab61-5a519ab1fbeb" }
]

const SESSIONSTATE_KEY = "QUICKLINKS"

const useQuickLinks = (): [links: spMainFolderDefinition[], loading: boolean] => {
    const [items, setItems] = useSessionStorageValue<spMainFolderDefinition[]>(SESSIONSTATE_KEY);
    const [loading, setLoading] = useState(true);
    const [initalLoad, setInitialLoad] = useState(true);

    const loadLatestItems = async () => {
        try {
            if (items && items.length > 0) {
                if (loading)
                    setLoading(false);
                return;
            }

            let response = await fetch("/homelinks");
            setLoading(false);

            if (response.ok) {
                let data = await response.json();
                setItems(data);
                return;
            }
        }
        catch (e) {
            console.error("Error getting quick links", e);
            setLoading(false);
        }
        setItems(mainDefaultFolder);
    }

    useEffect(() => {
        loadLatestItems();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items])

    useEffect(() => {
        if (initalLoad) {
            setInitialLoad(false);
            loadLatestItems();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return [items ?? [], loading];
}

export default useQuickLinks;