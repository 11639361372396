import './custom.css';
import Layout from './components/Layout'
import { Route, Routes } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import { initializeIcons } from "@fluentui/font-icons-mdl2"
import { useSecurityContext } from './security/useSecurityContext';
import LoginState from './security/types/LoginState';
import AccessDenied from './controls/AccessDenied';
import { ProgressSection } from "@churchofjesuschrist/eden-progress";
import LogOut from './controls/LoutOut';

initializeIcons();


const App = () => {
    const [info] = useSecurityContext();

    return (
        <Layout>
            <>
                {
                    info === LoginState.loggedIn
                        ? <div>
                            <LogOut></LogOut>
                            <Routes>
                                {AppRoutes.map((route, index) => {
                                    const { element, ...rest } = route;
                                    return <Route key={index} {...rest} element={element} />;
                                })}
                            </Routes></div>
                        : info === LoginState.accessDenied
                            ? <AccessDenied></AccessDenied>
                            : <ProgressSection>Loading..</ProgressSection>
                }
            </>
        </Layout>
    );
}

export default App