import { useNavigate } from "react-router-dom";
import { useSecurityContext } from "../security/useSecurityContext";
import { useEffect } from "react";
import LoginState, { TranslateLoginState } from "../security/types/LoginState";
import { ProgressSection } from "@churchofjesuschrist/eden-progress";
import AccessDenied from "./AccessDenied";

const Login = () => {
    const [info] = useSecurityContext();
    const naver = useNavigate();

    useEffect(() => {
        if (info === LoginState.loggedIn) {
            naver("/");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [info])

    if (info === LoginState.accessDenied) {
        return <AccessDenied></AccessDenied>
    }
    return <>
        <div>This is the login page</div>
        <div>{TranslateLoginState(info)}</div>
        <div><ProgressSection>Loading..</ProgressSection></div>
    </>
}

export default Login;