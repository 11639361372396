import { useEffect } from "react";
import useSessionStorageValue from "./useSessionStorageValue";

const useImageLinksPage = (sourcePageName: string) => {
    const cacheKey = "PAGE_LINKS_" + sourcePageName;
    const [links, setLinks] = useSessionStorageValue<string[]>(cacheKey);

    const loadLinks = async () => {
        if (links && links.length > 0) {
            return;
        }
        let result = await fetch("/movielistprovider?pageName=" + sourcePageName);
        if (result.ok) {
            setLinks(await result.json());
            return;
        }
    }

    useEffect(() => {
        loadLinks();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sourcePageName]);

    return links ?? [];
}

export default useImageLinksPage