import { NavLink, useParams } from "react-router-dom";
import useQuickLinks, { spMainFolderDefinition } from "./usings/useQuickLinks";
import { useEffect, useState } from "react";
import LinkFolderType from "./types/LinkFolderType";
import FolderContents from "./FolderContents";
import MoviePage from "./MoviePage";
import BreadCrumbs from "@churchofjesuschrist/eden-breadcrumbs"
import { H3 } from "@churchofjesuschrist/eden-headings";


const ItemDetails = () => {
    const { linkName } = useParams();
    const [quickLinks] = useQuickLinks();
    const [selectedLink, setSelectedLink] = useState<spMainFolderDefinition | null>(null);

    useEffect(() => {
        if (quickLinks.length > 0) {
            if (linkName && linkName.length > 0) {
                let matches = quickLinks.filter(Q => Q.label === linkName);
                if (matches && matches.length > 0) {
                    setSelectedLink(matches[0]);
                }
            }
        }

    }, [linkName, quickLinks])
    return <>
        <div className="sp-bread">
            <BreadCrumbs>
                <NavLink key="Home" to="/">Home</NavLink>
                <span key="link">{selectedLink?.label}</span>
            </BreadCrumbs>
        </div>
        <div className="aarl-page-content">
            <div><H3>{selectedLink?.label}</H3></div>
            <div className="aarl-spacer2"></div>
            {
                selectedLink
                    ? selectedLink.folderType === LinkFolderType.folder
                        ? <FolderContents item={selectedLink}></FolderContents>
                        : <MoviePage item={selectedLink}></MoviePage>
                    : <></>
            }
        </div>
    </>
}

export default ItemDetails;