import { Text1 } from "@churchofjesuschrist/eden-text";
import Bar from "@churchofjesuschrist/eden-bar";

const AccessDenied = () => {
    return <div className="aarl-page-content">
        <Bar
            color="#fda1b2"
            size="jumbo"
        />
        <div>
            <div className="aarl-spacer1 "></div>
            <Text1>I am sorry, you do not have access.</Text1>
            <div className="aarl-spacer1 "></div>
        </div>
        <Bar
            color="#fda1b2"
            size="jumbo"
        />
    </div>
}

export default AccessDenied;